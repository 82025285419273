body,html {
    font-family: "Inter", sans-serif; 
    background-color: #EBEDF3;
    height: 100%;
    margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* get id root */
#root {
  height: 100%;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.MuiPaper-root .MuiList-root .MuiButtonBase-root{
  white-space: normal;
}

input[type="time"]::-webkit-calendar-picker-indicator { 
  background: none; 
  display:none;
 }